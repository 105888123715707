import React, { useState } from 'react'; 
import cn from "classnames";
import * as axios from "axios";

import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import NavigationIcon from '@material-ui/icons/Navigation';
import Button from '@material-ui/core/Button'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Autocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
function sleep(delay = 0) {
	return new Promise(resolve => {
		setTimeout(resolve, delay);
	});
}
	
function time(){
	return parseInt(new Date().getTime()/1000)
} 
function gpsOn(){
	let gpsOno = localStorage.getItem('gpsOn');
	return gpsOno
} 
 
	const mapState = { center: [55.0044661442216,82.95886874198843], zoom: 15 };
 
	 
class AddMessage extends React.Component {
	 
  constructor(props) {
  
    super(props);
	let burial_id = time(); 
	//let gpsOn = gpsOn(); 
	if (navigator.geolocation) {
		console.log('Геолокация доступна'); // Геолокация доступна
//	console.log(lat2.Provider); // Геолокация доступна
	}
	else {
		console.log('Геолокация недоступна'); // Геолокация не доступна
	}   
	//let lng = position.coords.longitude; 

    this.state = {value1: '', value2: '', value4: localStorage.getItem('EmailWikiRipUser')};  

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this); 
    this.handleChange4 = this.handleChange4.bind(this); 
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleChange1(event) { // name, surname
    this.setState({value1: event.target.value});
  }
  handleChange2(event) { // location
    this.setState({value2: event.target.value});
  }  
  handleChange4(event) { // email
    this.setState({value4: event.target.value});
  }  
  
	
  handleSubmit(event) {    
    event.preventDefault();
  //  this.setState({value5: time()}); // id-шник добавляемого местоположения
	 
  //  let value3 = localStorage.getItem('gpsOn'); // координаты метки
    alert('Проверьте пожалуйста: ' + this.state.value1);
	axios.get(`https://asia-fort.ru/index.php?route=api/addmessage&name=${this.state.value1}&location=${this.state.value2}&email=${this.state.value4}`); 
	/* .then(response => {
		if (!response.ok) throw Error(response.statusText);
		return; // response.json()
	  }) */
	alert('Добавлено cообщение: ' + this.state.value1);
	localStorage.setItem('EmailWikiRipUser', this.state.value4);
	//localStorage.setItem('user_id', this.state.value6);
//	localStorage.setItem('gpsOn', '');
	//localStorage.getItem('EmailWikiRipUser')
  }
    
	 
  render() {
    return ( <Grid className='AddBurial_AddBurial root'>  
	<Grid className='mapH1 paper'><h2>Добавьте пожалуйста новое обращение, и мы Вам перезвоним.</h2></Grid>
	<Paper className="paper"> 
	<form onSubmit={this.handleSubmit} className="container" noValidate autoComplete="off">
	<Grid className="jkl tup tup2">  
	<TextField
	id="outlined-basic1"
	name="outlined-basic1"
	value={this.state.value} 
	
	onChange={this.handleChange1}
	
	lang="ru" data-ready="Говорите.."
	className="speech-input textField"
	label="Введите имя"
	margin="normal" 
	
	variant="outlined"
	></TextField>
	
	<TextField
	id="outlined-basic2"
	name="outlined-basic2"
	value={this.state.value} 
	
	onChange={this.handleChange2}
	
	className="textField"
	label="Телефон"
	margin="normal" 
	
	variant="outlined"
	></TextField> 
	{!localStorage.getItem('EmailWikiRipUser') &&
	<TextField
	id="outlined-basic3"
	name="outlined-basic3"
	value={this.state.value} 
	
	onChange={this.handleChange4}
	
	className="textField"
	label="Ваш email"
	margin="normal" 
	
	variant="outlined"
	></TextField>}
	<Button
	type="submit"
	id="outlined-basic4"
	name="outlined-basic4"
	fullWidth
	variant="contained"
	color="primary"
	className="submit"
	>
	Добавить новое обращение
	</Button>
	</Grid>
	</form>
    </Paper> 
	
	<Grid className='mapH1 paper'>
	<h2>ООО АзияФорт — транспортная компания</h2>
<p dangerouslySetInnerHTML={{__html: "<a href='tel:83832132575'>☎ 8(383) 213-25-75</a>"}} /> 
<p dangerouslySetInnerHTML={{__html: "<a href='tel:83833751210'>☎ 8(383) 375-12-10</a>"}} /> 
<p> 
	ф. 8(383) 206-42-08
</p>
<p dangerouslySetInnerHTML={{__html: "<a href='mailto:elen.s@list.ru'>Elen.s@list.ru</a>"}} />
<p>
г. Новосибирск
</p>
<p>
Декабристов, 92
</p>
<h2>
Схема проезда:</h2> 
	<h2>Мы находимся в Октябрьском районе</h2> 
	<p className='textNoFound' dangerouslySetInnerHTML={{__html: "Спросите у диспетчера, позвоните по телефону <a href='tel:83832132575'> 8 (383) 213-25-75</a> в Новосибирске. Или напишите нам на Телеграм, Whatsapp или Skype <br/><a href='https://api.whatsapp.com/send?phone=+79139852575&amp;text=Здравствуйте! 😊 Хочу задать вопрос по аренде спецтехники'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/whatsapp.png /></a><a href='https://t.me/polakov2018'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/telegram.png /></a><a href='skype:sergei_polakov?chat'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/skype.png /></a><a href='tel:+73832132575'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/viber.png /></a>"}} />   
	</Grid> 
	<YMaps>
      <Map state={mapState} width={'100%'} height={600}>
        <Placemark
          geometry={{
            type: 'Point',
		  coordinates: [55.0044661442216,82.95886874198843]
          }}
          options={{
            preset: 'islands#redDotIcon',
         //   draggable: true
          }}
          events={{
           /*  dragend:{function (event){
                console.log("function worket" + event.get('target').geometry.getCoordinates()); 
              }} */
          }} 
		  
	/* onDragEnd={(e) => {
		localStorage.setItem('gps', e.get('target').geometry.getCoordinates())
		localStorage.setItem('gpsOn', e.get('target').geometry.getCoordinates())
			let fd = e.get('target').geometry.getCoordinates() + ''
			let  fdlat = fd.split(',')[0]
			let  fdlng = fd.split(',')[1]
		localStorage.setItem('lat', fdlat)
		localStorage.setItem('lng', fdlng)
		}}  */
		/>
      </Map>
    </YMaps>
	
	</Grid> 
    );
  }
}  

export default AddMessage;