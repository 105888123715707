import React, { Component } from 'react';
import './App.css'; 
import { HashRouter, Route, withRouter } from "react-router-dom";
 
import NavbarIcons from './components/Navbar/NavbarIcons';  
import UsersContainer from "./components/Users/UsersContainer"; 
import PriceContainer from "./components/Users/PriceContainer";  
import UsersaddloveContainer from "./components/UsersBurial/UsersBurialContainer";  
import ExcavsContainer from "./components/Excav/ExcavContainer";  
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp"; 
import AddMessage from "./components/common/AddMessage/AddMessage"; 


import HeaderContainer from "./components/Header/HeaderContainer";
import LoginPage from "./components/Login/Login";
import { connect, Provider } from "react-redux";
import { compose } from "redux";
import { initializeApp } from "./redux/app-reducer";
import Preloader from "./components/common/Preloader/Preloader";
import NoFound from "./components/common/NoFound/NoFound";
import store from "./redux/redux-store";
import { withSuspense } from "./hoc/withSuspense";
 
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    }, 
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    }, 
}));

const DialogsContainer = React.lazy(() => import('./components/Dialogs/DialogsContainer'));
const ProfileContainer = React.lazy(() => import('./components/Profile/ProfileContainer'));



class App extends Component {
    componentDidMount() {
        this.props.initializeApp();
    }

    render() {
        if (!this.props.initialized) {
            return <Preloader />
        }

        return (
            <Grid className='app-wrapper'>
                <HeaderContainer />
                <Grid className='app-wrapper-content'>
                    <Route path='/dialogs'
                        render={withSuspense(DialogsContainer)} />
                    <Route path='/profile/:userId?'
                        render={withSuspense(ProfileContainer)} /> 
                    <Route path='/addmessage'
                        render={() => <AddMessage />} />
                    <Route exact path='/'
                        render={() => <UsersContainer />} />
                    <Route path='/price'
                        render={() => <PriceContainer />} />
                    
                    <Route path='/excav'
                        render={() => <ExcavsContainer />} />
                    <Route path='/login'
                        render={() => <LoginPage />} />
                    <Route path='/signin' component={SignIn} />
                    <Route path='/signup' component={SignUp} /> 
                    <Route path='/addlove' component={UsersaddloveContainer} /> 
                    <NavbarIcons />
                </Grid>
            </Grid>
        )
    } 
}

const mapStateToProps = (state) => ({
    initialized: state.app.initialized
})

let AppContainer = compose(
    withRouter,
    connect(mapStateToProps, { initializeApp }))(App);

const JSApp = (props) => {
    return <HashRouter >
        <Provider store={store}>
            <AppContainer />
        </Provider>
    </HashRouter> 
}

export default JSApp;
