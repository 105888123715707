import React from 'react';
import s from './Header.module.css';
import {NavLink} from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
 
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';  
import Grid from '@material-ui/core/Grid'; 

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    }, 
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    }, 
}));

const Header = (props) => {
    return  <header className={s.header}>  
    <YMInitializer accounts={[49217122]} options={{webvisor: true}}/>
    <Grid className="border_bottom_grey font_086em display_none">
        <Grid className="inline"> 
			<Grid className='inl'>
				<NavLink to="/price" className='buld' activeClassName="activeLink">1</NavLink> 
				<NavLink to="/price" className='excav' activeClassName="activeLink">2</NavLink> 
				<NavLink to="/price" className='avtokr' activeClassName="activeLink">3</NavLink> 
				<NavLink to="/price" className='samosval' activeClassName="activeLink">4</NavLink> 
				<NavLink to="/price" className='pogruz' activeClassName="activeLink">5</NavLink> 
				<NavLink to="/price" className='beton' activeClassName="activeLink">6</NavLink>
            </Grid>  
			<p className='inlr' dangerouslySetInnerHTML={{__html: "Позвоните <a href='tel:83832132575'>☎ 8(383) 213-25-75</a>"}} /> 
        </Grid>  
    </Grid>  
	{/*<Grid className={s.loginBlock}>
            { props.isAuth
                ? <Grid>{props.login} - <button onClick={props.logout}>Log out</button> </Grid>
                : <NavLink to={'/login'}>Login</NavLink> }
	</Grid>*/} 
    <Grid className="logo">
        <Grid className="logo_img">
            <NavLink to="/"> 
                <img src="./logo192.png" className="logo_main" title="АзияФорт - аренда спецтехники" alt="АзияФорт - аренда спецтехники" /> 
            </NavLink>
        </Grid>
        {/*<Grid className="contein_logo_text">
            <NavLink to="/">
                <span className="logo_text">АзияФорт</span>
                <span className="logo_slogan"><h1> аренда спецтехники</h1></span>
            </NavLink>
        </Grid>*/} 
    </Grid> 
</header>  


const UserReadyLogin = ({ name, imgUrl }) => {
    return (
      <React.Fragment>
        <p>Привет, {name}!</p>
        <img src={imgUrl} alt="profle" />
      </React.Fragment>
    )
  }
  
  UserReadyLogin.propTypes = {
    name: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
  }
}

export default Header