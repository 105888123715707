import {createSelector} from "reselect";

const getUsersSelector = (state) => {
    return state.usersPage.users;
}
 
export const getUsers = createSelector(getUsersSelector, (users) => {
	 if (!users)
		return 0;
    else 
		return users.filter(u => true);
})

export const getPageSize = (state) => {
    return state.usersPage.pageSize;
}

export const getTotalUsersCount = (state) => {
    return state.usersPage.totalUsersCount;
}

export const getCurrentPage = (state) => {
    return state.usersPage.currentPage;
	}

export const getIsFetching = (state) => {
    return state.usersPage.isFetching;
}
export const getIsFetchingNo = (state) => {
    return state.usersPage.isFetchingNo;
}
export const getFollowingInProgress = (state) => {
    return state.usersPage.followingInProgress;
}

export const countSomethingDifficult = (state) => {
    debugger
    //for... math... big arrays
    let count = 23;
    return count;
}