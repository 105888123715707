import React from 'react';
import styles from './users.module.css';
import * as axios from 'axios';
import uPhoto from '../../assets/images/user.png';

//import uPhoto from "../../assets/images/placeholder.png";
import { NavLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import FavoriteIcon from '@material-ui/icons/Favorite';
//import NavigationIcon from '@material-ui/icons/Navigation';
import Button from '@material-ui/core/Button';



import NoFound from "../common/NoFound/NoFound";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
   // maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  textField: {
    margin: 'auto',
    width: '100%',
    float: 'left',
  //  maxWidth: 500,
  },
}));

class UsersBurial extends React.Component {
	
	//const classes = useStyles(); 
	
    componentDidMount() {
	let value33 = localStorage.getItem('user_id'); 
        axios.get(`https://asia-fort.ru/index.php?route=api/searchten22&searchhome22=${value33}`)
            .then(response => { 
				if(response.data.items){
					 this.props.setUsers(response.data.items); 
				}
				else{
				///	this.props.toggleIsFetchingNo();
				} 
            });
    }

    render() {
        return <Grid>
		<h2>Добавлено в память:</h2>
            {
                this.props.users.map(u => <Grid className='root' id='rootMain' key={u.id}>  	
                 <Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className="tup" item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/profile/' + u.id}>
								<img className="img"
                                    alt={u.name} src={u.image_remote1} />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className="tup" item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/profile/' + u.id}> {u.name}</NavLink>
                                </Typography>
                                { /*  <Typography variant="body2" color="textSecondary">
                                    <NavLink to={'/profile/' + u.id}>
                                        {u.mpn}
                                        {u.isbn}
                                    </NavLink>
                                     </Typography>*/}
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                  
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
							<Typography variant="subtitle1">
								<NavLink to={'/profile/' + u.id} className="flower0">{ u.pr }</NavLink>
							</Typography>
						</Grid>
                    </Grid>
                </Grid>
            </Paper>
                </Grid>)
            }
        </Grid>
    }
}


export default UsersBurial;