import React from 'react';
import styles from "./users.module.css";
import userPhoto from "../../assets/images/placeholder.png";
import userPhoto1 from "../../assets/images/492x2771.jpg";
import userPhoto2 from "../../assets/images/kato_35-300x225-262x262.jpg";
import userPhoto3 from "../../assets/images/950k-1366x768-large-664d7a743b251c00278a8a6efa348105af7d4d4b-492x2777.png";
import userPhoto4 from "../../assets/images/bd8e71b6d16d3039989dd06ff5b11904-492x277.jpg";
import userPhoto5 from "../../assets/images/greyder-xcmg-gr180-492x277.jpg";
import userPhoto6 from "../../assets/images/41y.jpg";
import userPhoto7 from "../../assets/images/2490-492x2778.jpg";
import userPhoto8 from "../../assets/images/trovs2.png";
import userPhoto9 from "../../assets/images/7-492x277.jpg";
import userPhoto10 from "../../assets/images/41.jpg";
import userPhoto11 from "../../assets/images/Komatsu-WA800-333-262x262.jpg";
import userPhoto12 from "../../assets/images/JCB js 160 w-274x340.jpg";
import userPhoto13 from "../../assets/images/arenda_ekskavator_pogruzchik_otek.jpg";
import userPhoto14 from "../../assets/images/large-pic-1-492x27710.jpg";
import userPhoto15 from "../../assets/images/bocka-388x300.png";
import userPhoto16 from "../../assets/images/bocka-388x300.png"; 
import userPhoto17 from "../../assets/images/411.jpg";
import userPhoto18 from "../../assets/images/dump-truck.png";
import userPhoto19 from "../../assets/images/BAR-NA-BAZE-MTZ.-GRUNTOREZ.jpg"; 
import userPhoto20 from "../../assets/images/bobkat-492x328.jpg"; 
import { NavLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import LocalPhoneIcon from '@material-ui/icons/PhoneRounded';
//import NavigationIcon from '@material-ui/icons/Navigation';
import Button from '@material-ui/core/Button'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
     //   maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '200px',
        maxHeight: '100%',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    textField: {
        margin: 'auto',
        width: '100%',
        float: 'left',
      //  maxWidth: 500,
    },
}));
	

function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}	

let User = () => {
    const classes = useStyles();
    return ( <Grid> 
	<Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto1 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда автовышки от 10 до 50 метров</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto2 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда автокрана от 10 до 220 тонн</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1300 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto3 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда бульдозера от 7 до 34 тонн</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto4 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда катков дорожных</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1800 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto5 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Грейдера</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2200 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto6 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Гидромолота</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2200 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto7 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Длинномера</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1300 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto8 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Самогруза</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto9 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Самосвала </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">800 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto10 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Услуги Трала </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto11 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Фронтального погрузчика</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1600 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto12 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Экскаватора</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2200 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto13 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Экскаватора погрузчика</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1600 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto14 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage'}> Аренда Дизельного компрессора</NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">800 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid> 
		<Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto17 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Ямобура </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1600 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto18 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Эвакуатора </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1500 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid><Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto19 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Бара-грунтореза(на базе МТЗ) </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">2000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
		<Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto20 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда мини-погрузчика </NavLink>
                                </Typography> 
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1200 рублей в час</NavLink>
								</Typography>
							</Grid> 
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid> 
		<Grid className="root"> 
			<Paper className="paper">
                <Grid container spacing={2}>
                    <Grid className='tup' item id="itemGrid">
						<ButtonBase className="image">
                            <NavLink to={'/addmessage'}>
								<img className="img"
                                    src={ userPhoto15 } />
                            </NavLink>
                        </ButtonBase>
                    </Grid>
                    <Grid className='tup' item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <NavLink to={'/addmessage/'}> Аренда Илососа</NavLink> 
                                </Typography>  
                                <Typography variant="body2" gutterBottom> 
									<NavLink to={'/addmessage/'}>Аренда ассенизаторной машины</NavLink>
                                </Typography>  
							</Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ cursor: 'pointer' }}>
									
									<NavLink to={'/addmessage'}>
									<Fab className="fab">
										<LocalPhoneIcon />
										</Fab> 
										<button>
                                            Заказать быстро, а мы перезвоним
										</button> 
									</NavLink>
									
                                </Typography>
                            </Grid>
							<Grid item>
								<Typography variant="subtitle1">
									<NavLink to={'/addmessage'} className="flower0">1000 рублей в час</NavLink>
								</Typography>
							</Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Paper>
        </Grid> 
        </Grid> )
}

export default User;