import React from 'react';
import s from './Navbar.module.css';
import { NavLink } from "react-router-dom";
import addbPhoto from "../../assets/images/is576cop.png";
import homePhoto from "../../assets/images/img_222286.png";
import lovePhoto from "../../assets/images/icons896.png";
import rubPhoto from "../../assets/images/icons8967.png";
import { makeStyles } from '@material-ui/core/styles';   
import Grid from '@material-ui/core/Grid'; 

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    }, 
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    }, 
}));


const Navbar = () => {
    return (<Grid className="bottomnav">
        <nav className={s.nav}>
            <Grid className='left'>
				<NavLink exact to="/" activeClassName="activeLink"><img src={homePhoto} /></NavLink>
            </Grid> 
			<Grid className='left price'>
				<NavLink to="/price" activeClassName="activeLink"><img src={rubPhoto} /></NavLink>
            </Grid>
            <Grid className='right'>
				<NavLink to="/addmessage" activeClassName="activeLink"><img src={addbPhoto} /></NavLink>
            </Grid> 
        </nav>
    </Grid>
    )
}

export default Navbar;