import React from 'react';
import {connect} from 'react-redux';
import {
    follow,
    setCurrentPage,
    unfollow, toggleFollowingProgress, requestUsers
} from '../../redux/users-reducer';
import Users from './Users';
import Preloader from "../common/Preloader/Preloader";
import NoFound from "../common/NoFound/NoFound";
import {compose} from "redux"; 

import * as axios from "axios";
import {
    getCurrentPage,
    getFollowingInProgress,
    getIsFetching,
    getIsFetchingNo,
    getPageSize,
    getTotalUsersCount, getUsers
} from "../../redux/users-selectors"; // приходят данные, необходимые для отрисовки блока...

//	axios.get(`http://ip-api.io/api/json/`)
//  .then(response => console.log(JSON.parse(response)))
//  .catch(err => console.log(err))
  
  
function time(){
	return parseInt(new Date().getTime()/1000)
} 

	let user_id = time();
	
	if(!localStorage.getItem('user_id')){ 
		localStorage.setItem('user_id', user_id);
	}
	
class UsersContainer extends React.Component {
    componentDidMount() { // первоначальная отрисовка видимо
        const {currentPage, pageSize} = this.props;
        this.props.getUsers(currentPage, pageSize);
    }

    onPageChanged = (pageNumber) => {// сработанный коллбэк с параметром номера страницы
        const {pageSize} = this.props;
        this.props.getUsers(pageNumber); // дальше летит два параметра pageNumber, pageSize
    }
/* распечатка пришедших из redux/users-reducer */ 
debugger;
    render() {
	
        return <>
            {this.props.isFetching ? <Preloader/> : null} 
            <Users totalUsersCount={this.props.totalUsersCount}
			
                   pageSize={this.props.pageSize}
                   currentPage={this.props.currentPage}
                   onPageChanged={this.onPageChanged}
                   users={this.props.users}
                   follow={this.props.follow} 
                   unfollow={this.props.unfollow} 
                   isFetchingNo={this.props.isFetchingNo} 
                   followingInProgress={this.props.followingInProgress}
            /> 
        </>
    }
}

let mapStateToProps = (state) => {
    return {
        users: getUsers(state),
        pageSize: getPageSize(state),
        totalUsersCount: getTotalUsersCount(state),
        currentPage: getCurrentPage(state),
        isFetching: getIsFetching(state),
        isFetchingNo: getIsFetchingNo(state),
        followingInProgress: getFollowingInProgress(state)
    }
}


export default compose(
    connect(mapStateToProps, {follow, unfollow, setCurrentPage, toggleFollowingProgress, getUsers: requestUsers})
)(UsersContainer)