import React, { Component } from 'react';
//import { tele } from "https://t.me/polakov2018";
 
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';  
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import Button from '@material-ui/core/Button';
import addbPhoto from "../../../assets/images/is576cop.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  textField: {
    margin: 'auto',
    width: '100%',
    float: 'left',
    maxWidth: 500,
  },
}));

let NoFound = (props) => {
	const classes = useStyles();
	
    return <Grid className='MuiPaper-root MuiPaper-root2'>
    <h2>Не нашли что искали? Что можно сделать?</h2> 
	<p className='textNoFound' dangerouslySetInnerHTML={{__html: "Спросите у диспетчера, позвоните по телефону <a href='tel:83832132575'> 8 (383) 213-25-75</a> в Новосибирске. Или напишите нам на Телеграм, Whatsapp или Skype <br/><a href='https://api.whatsapp.com/send?phone=+79139852575&amp;text=Здравствуйте! 😊 Хочу задать вопрос по аренде спецтехники'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/whatsapp.png /></a><a href='https://t.me/polakov2018'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/telegram.png /></a><a href='skype:sergei_polakov?chat'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/skype.png /></a><a href='tel:+73832132575'><img class='imgNon' src=https://www.xn--80aavewqje8j.xn--p1ai/wp-content/uploads/2017/03/viber.png /></a>"}} />   
</Grid>
}

export default NoFound;